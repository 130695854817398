<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          src="@/assets/image/logo.png"
          class="my-3"
          contain
          height="200"
        />
      </v-col>

      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Chào mừng tới với PTA Media
        </h1>

        <p class="subheading font-weight-regular">
          Đây là cổng thông tin của "Mục Vụ Công Nghệ Và Truyền Thông Tin Lành"
          <br />
          Nhắn tin với chúng tôi qua
          <a href="https://www.facebook.com/CongNgheTinLanh" target="_blank">Facebook</a>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Home",
};
</script>
